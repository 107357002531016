import { clone } from 'lodash';
import numeral from 'numeral';

import {
  IFeasibilityAnalysisSimulationGeneralReport,
  IFeasibilityAnalysisSimulationUnitReport,
} from '@contexts/feasibility-analysis/types';

import { ENERGY_DISTRIBUTION_COMPANIES, TARIFFS_INDEXER } from '@utils/constants';

const parseGeneralInformationsTable = (
  generalReport: IFeasibilityAnalysisSimulationGeneralReport,
  leadName?: string,
  unitReport?: IFeasibilityAnalysisSimulationUnitReport,
  generalAnaylysis?: boolean,
): Record<string, any>[] | undefined => {
  const formatCommonDataEnergyValue = (unitReportData: any, generalReportData: any) => {
    const usedData = generalAnaylysis ? generalReportData : unitReportData;
    return usedData ? `${usedData} kW` : '-';
  };

  const commonData = [
    {
      title: 'Demanda Cativo FP',
      value: formatCommonDataEnergyValue(
        unitReport?.acrContractedDemandOffPeak,
        generalReport.totalGroupAcrContractedDemandOffPeak,
      ),
    },
    {
      title: 'Demanda Cativo P',
      value: formatCommonDataEnergyValue(
        unitReport?.acrContractedDemandPeak,
        generalReport.totalGroupAcrContractedDemandPeak,
      ),
    },
    {
      title: 'Demanda Livre FP',
      value: formatCommonDataEnergyValue(
        unitReport?.aclContractedDemandOffPeak,
        generalReport.totalGroupAclContractedDemandOffPeak,
      ),
    },
    {
      title: 'Demanda Livre P',
      value: formatCommonDataEnergyValue(
        unitReport?.aclContractedDemandPeak,
        generalReport.totalGroupAclContractedDemandPeak,
      ),
    },
  ];

  if (!generalAnaylysis && unitReport) {
    const consumptionAverageCalculator = (consumption: any) => {
      const consumptionArray: any[] = Object.values(consumption);

      return numeral(consumptionArray.reduce((a, b) => a + b, 0) / consumptionArray.length / 1000).format('0,0.00');
    };
    const tariffData =
      Object.entries(TARIFFS_INDEXER).find((tariff) => tariff[0] === unitReport.tariff.toString())?.[1] || null;
    const consumptionOffPeakAverage = consumptionAverageCalculator(unitReport.consumptionOffPeak);
    const consumptionPeakAverage = consumptionAverageCalculator(unitReport.consumptionPeak);

    const unitBaseData = [
      {
        title: 'Nome',
        value: leadName,
      },
      {
        title: 'Unidade',
        value: unitReport.nickname,
      },
      {
        title: 'Distribuidora',
        value:
          Object.entries(ENERGY_DISTRIBUTION_COMPANIES).find(
            (cia) => cia[1] === unitReport.premises?.edcInfo?.name?.toString(),
          )?.[1] || '-',
      },
      {
        title: 'Subgrupo',
        value: tariffData?.subgroup || '-',
      },
      // INSERT HERE COMMON DARAT
      {
        title: 'Modalidade tarifária',
        value: tariffData?.tariff_modality || '-',
      },
      {
        title: 'Consumo médio P',
        value: `${consumptionPeakAverage.toString()} MWh`,
      },
      {
        title: 'Consumo médio FP',
        value: `${consumptionOffPeakAverage.toString()} MWh`,
      },
    ];

    const finalData = clone(unitBaseData);

    finalData.splice(4, 0, ...commonData);
    return finalData;
  } else if (generalAnaylysis && generalReport) {
    const groupBaseData = [
      {
        title: 'Grupo',
        value: leadName,
      },
      {
        title: 'Distribuidoras',
        value: generalReport.edcs.toString().replace(',', ', ') || '-',
      },
    ];

    const finalData = clone(groupBaseData);

    finalData.splice(-1, 0, ...commonData);
    return finalData;
  }
};

export default parseGeneralInformationsTable;
